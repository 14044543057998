<template>
    <div class="specializationCard" align="center" v-if="!specialization_data.view_more && !specialization_data.empty_div" @click="redirectToViewDoctorsPage(specialization_data._id)">
        <div class="subComponentDiv rounded-lg d-flex justify-center align-center mb-1" style="background-color: #EAF4FF; width: 90px; vertical-align: top;">
            <img class="rounded-lg" style="width: 60%; height: 60%;" :src="'https://s3iconimages.mymedicine.com.mm/' + specialization_data.image_filename" alt="Category">
        </div>
        
        <div style="vertical-align: bottom;">
            <p class="ma-0 normaltext px-1 line-clamp" style="width: 100px;" align="center">{{ specialization_data.specialization_name }}</p>
        </div>
    </div>

    <div class="specializationCard" align="center" v-else-if="specialization_data.view_more" @click="redirectToSpecializationsPage()">
        <div>
            <div class="rounded-lg d-flex justify-center align-center mb-1 subComponentDiv" style="width: 90px; background-color: #EAF4FF;">
                <img style="width: 60%; height: 60%;" src="https://s3iconimages.mymedicine.com.mm/view_more.svg" alt="View More">
            </div>

            <div>
                <!-- <p class="ma-0 d-inline-block text-truncate pt-2 normaltext" style="width: 80%;" align="center">View More</p> -->
                <p class="ma-0 normaltext px-1" style="width: 90px;" align="center">{{ $t("Customer.Home.View_More_Specialities") }}</p>
            </div>
        </div>
    </div>

    <div v-else>
        <div class="rounded-lg d-flex justify-center align-center" style="width: 116px; height: 155px; margin-bottom: 6px; background-color: white;">
        </div>
    </div>
</template>

<script>
import axios from "axios"
    export default {
        name: 'specializationSubComponent',
        props: {
            specialization_data: {
                type: Object,
                requied: true
            },
            pageName:{
                type: String,
                requied: true
            }
        },
        methods: {
            redirectToViewDoctorsPage(specializationId) {
                localStorage.setItem('bookingType', 'regularAppointment');
                let token = this.$cookies.get("customerToken") === null ? '': this.$cookies.get("customerToken");
                var viewedSpecializationTableBody = {
                    token: token,
                    typeOfUser: 'CUSTOMER',
                    specializationId: specializationId,
                    pageName: this.$props.pageName
                }
                axios.post(process.env.VUE_APP_BACKEND_URL + '/viewedSpecializationTable', viewedSpecializationTableBody);
                this.$router.push({
                    path: '/customer/viewDoctors?type=Category&type1='+specializationId + '&type2=' + this.specialization_data.specialization_name
                });
            },
            redirectToSpecializationsPage(){
                this.$router.push({
                    name: 'SpecializationsPage'
                });
            }
        }
    }
</script>

<style>
.normaltext{
    font-size: 12px;
}

.line-clamp {
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>

<style scoped>
.specializationCard{
    aspect-ratio: 2/3;
}

.subComponentDiv{
    width: 100% !important;
    aspect-ratio: 1/1;
}

.emptyDiv{
    width: 116px;
    height: 155px;
    margin-bottom: 6px;
    background-color: white;
}
</style>